.cards {
    display: flex;
    flex-direction: column;
    
    gap: 15px;
  }
  
  /* .cards .red {
    background-color: #f43f5e;
  }
  
  .cards .blue {
    background-color: #3b82f6;
  }
  
  .cards .green {
    background-color: #22c55e;
  } */
  
  .cards .card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 130px;
    width: auto;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    transition: 400ms;
  }
  
  .cards .card p.tip {
    font-size: 1.5em;
    font-weight: 700;
  }
  
  .cards .card p.second-text {
    font-size: 1em;
    color: gray;
    
  }
  
  .cards .card:hover {
    transform: scale(1.1, 1.1);
  }
  
  .cards:hover > .card:not(:hover) {
    filter: blur(10px);
    transform: scale(0.9, 0.9);
  }
  @media (max-width: 768px) {
    .cards .card {
        height: 120px;
        width: auto;
    }

    .cards .card p.tip {
        font-size: 1em;
    }

    .cards .card p.second-text {
        font-size: 0.9em;
    }
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .cards .card {
        height: 100px;
        width: auto;
    }

    .cards .card p.tip {
        font-size: 1em;
    }

    .cards .card p.second-text {
        font-size: 0.8em;
    }
    
    .cards {
        flex-direction: column;
    }
}
.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in.active {
  opacity: 1;
}