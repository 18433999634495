bg
/*txt*/
/*success*/
.buttons {
    display: flex;
    justify-content: space-around;
    top: 20px;
    left: 20px;
    text-align: center;
    padding: 4px;
    
    
    
  }
  
  .buttons a {
    width: 150px;
    height: 50px;
    
    background-color: white;
    margin: 20px;
    color: #111818;
    position: relative;
    padding-top: 25px;
    /* overflow: hidden; */
    border-radius: 50px;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 500;
    text-transform: uppercase;
    transition: all 0.3s ease;
    cursor: pointer;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
  
  .buttons a:before, .buttons a:after {

    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    background-color: #44d8a4;
    transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
  }
  
  .buttons a:before {
    right: 0;
    top: 0;
    transition: all 0.5s cubic-bezier(0.35, 0.1, 0.25, 1);
  }
  
  .buttons a:after {
    left: 0;
    bottom: 0;
  }
  
  .buttons a span {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    z-index: 1;
  }
  
  .buttons a span:before, .buttons a span:after {
    /* padding: 4px; */
    content: "";
    position: absolute;
    width: 2px;
    height: 0;
    background-color: #44d8a4;
    transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
  }
  
  .buttons a span:before {
    right: 0;
    top: 0;
    transition: all 0.5s cubic-bezier(0.35, 0.1, 0.25, 1);
  }
  
  .buttons a span:after {
    left: 0;
    bottom: 0;
  }
  
  .buttons a p {
    padding: 0;
    margin: 0;
    transition: all 0.4s cubic-bezier(0.35, 0.1, 0.25, 1);
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  /* .buttons a p:before, .buttons a p:after {
    position: absolute;
    width: 100%;
    transition: all 0.4s cubic-bezier(0.35, 0.1, 0.25, 1);
    z-index: 1;
    left: 0;
  } */
  
  /* .buttons a p:before {
    content: attr(data-title);
    top: 50%;
    transform: translateY(-50%);
  } */
  
  .buttons a p:after {
    content: attr(data-text);
    top: 150%;
    
    color: black;
  }
  
  .buttons a:hover:before, .buttons a:hover:after {
    width: 100%;
  }
  
  .buttons a:hover span {
    z-index: 1;
  }
  
  .buttons a:hover span:before, .buttons a:hover span:after {
    height: 100%;
  }
  
  .buttons a:hover p:before {
    top: -50%;
    transform: rotate(5deg);
  }
  
  .buttons a:hover p:after {
    top: 50%;
    transform: translateY(-50%);
  }
  
  .buttons a.start {
    background-color: #44d8a4;
    box-shadow: 0px 5px 10px -10px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease;
  }
  
  .buttons a.start p:before {
    top: -50%;
    transform: rotate(5deg);
  }
  
  .buttons a.start p:after {
    color: white;
    transition: all 0s ease;
    content: attr(data-start);
    top: 50%;
    transform: translateY(-50%);
    animation: start 0.3s ease;
    animation-fill-mode: forwards;
  }
  
  @keyframes start {
    from {
      top: -50%;
    }
  }
  
  .buttons a.start:hover:before, .buttons a.start:hover:after {
    display: none;
  }
  
  .buttons a.start:hover span {
    display: none;
  }
  
  .buttons a:active {
    outline: none;
    border: none;
  }
  
  .buttons a:focus {
    outline: 0;
  }
 
  @media only screen and (max-width: 768px) {
    /* Styles for screens with a width of 768 pixels or less */

    .buttons {
        flex-direction: column; /* Change the flex direction to column */
        margin-left: 50px; /* Add left margin of 50 pixels */
    }

    .buttons a {
        width: 50%; /* Set the width of buttons to 50% of the container */
        height: 40px; /* Set the height of buttons to 40 pixels */
        margin: 10px 0; /* Set margin of 10 pixels above and below each button */
    }
}